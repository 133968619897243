import { Component, OnInit, inject } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";

@Component({
  selector: "pa-notfound-page",
  templateUrl: "./not-found-page.component.html",
  styleUrls: ["./error-page.component.scss"],
  standalone: true,
  imports: [TranslateModule],
})
export class NotFoundPageComponent implements OnInit {
  private activatedRoute = inject(ActivatedRoute);
  private router = inject(Router);

  status?: number;
  message?: string;
  details?: string;

  ngOnInit(): void {
    const data = this.router.getCurrentNavigation()?.extras?.state || this.activatedRoute.snapshot.data;
    // @ts-expect-error (ignore ts error)
    this.status = data?.status;
    // @ts-expect-error (ignore ts error)
    this.message = data?.message ?? ``;
    if (typeof this.message !== "string") {
      this.message = JSON.stringify(this.message);
    }

    // @ts-expect-error (ignore ts error)
    this.details = data?.details ?? `Page '${this.activatedRoute.snapshot.url.toString()}'`;
  }
}
