<div class="error-container">
  <h2>{{ 'error.notfound.title' | translate }}!</h2>
  <div class="error-message">
    <p>{{ 'error.notfound.description' | translate }}.</p>
    @if (status) {
      <p>
        <b>{{ 'error.status' | translate }}:</b> {{ status }}.
      </p>
    }
    @if (message) {
      <p>
        <b>{{ 'error.message' | translate }}:</b> {{ message }}.
      </p>
    }
    @if (details) {
      <div>
        <b>{{ 'error.details' | translate }}:</b>
        <pre class="error-details">{{ details }}</pre>
      </div>
    }
  </div>
</div>
